// Generated by Framer (24f4d74)

import * as React from "react";
import { motion, LayoutGroup } from "framer-motion";
import { useOnVariantChange, useActiveVariantCallback, Image, addFonts, withCSS, addPropertyControls, ControlType, cx, useVariantState, CycleVariantState, getFonts } from "framer";
import Topbar from "https://framerusercontent.com/modules/AdYb8XieZeD9vYpeRJB5/k99SKEihaR1p9vRdDoJ9/pQWsMWkcK.js";
const TopbarFonts = getFonts(Topbar);

const cycleOrder = ["ZcHEPO6GU", "euAtUOstE"];

const variantClassNames = {ZcHEPO6GU: "framer-v-13cnx0t", euAtUOstE: "framer-v-9xbf21"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Variant 1": "ZcHEPO6GU", "Variant 2": "euAtUOstE"};

const transitions = {default: {type: "spring", ease: [0.44, 0, 0.56, 1], duration: 0.3, delay: 0, stiffness: 500, damping: 60, mass: 1}};

const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

const useRandomID = () => {const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "ZcHEPO6GU", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const { variants, baseVariant, gestureVariant, classNames, transition, setVariant, setGestureState } = useVariantState({defaultVariant: "ZcHEPO6GU", variant, transitions, variantClassNames, cycleOrder});
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID();

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-XjPHh", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-13cnx0t", className)} style={{...style}} layoutId={"ZcHEPO6GU"} transition={transition} layoutDependency={layoutDependency} background={null} data-framer-name={"Variant 1"} ref={ref} {...addPropertyOverrides({euAtUOstE: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><motion.div style={{}} className={"framer-yri3j9-container"} transformTemplate={(_, t) => `translate(-50%, -50%) ${t}`} layoutId={"TcOo_Zk5d-container"} transition={transition} layoutDependency={layoutDependency}><Topbar width={"100%"} height={"100%"} layoutId={"TcOo_Zk5d"} id={"TcOo_Zk5d"} variant={"eoTXJYY6N"} style={{width: "100%"}}/></motion.div></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-XjPHh [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-XjPHh * { box-sizing: border-box; }", ".framer-XjPHh .framer-13cnx0t { position: relative; overflow: hidden; width: 1200px; height: 80px; }", ".framer-XjPHh .framer-yri3j9-container { position: absolute; width: 100%; height: auto; left: 50%; top: 50%; flex: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 80
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"euAtUOstE":{"layout":["fixed","fixed"]}}}
 */
const FramerXf6Wod9f7: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerXf6Wod9f7;

FramerXf6Wod9f7.displayName = "navie";

FramerXf6Wod9f7.defaultProps = {width: 1200, height: 80};

addPropertyControls(FramerXf6Wod9f7, {variant: {type: ControlType.Enum, title: "Variant", options: ["ZcHEPO6GU", "euAtUOstE"], optionTitles: ["Variant 1", "Variant 2"]}} as any);

addFonts(FramerXf6Wod9f7, [...TopbarFonts]);